<template>
  <div class="row">
    <div class="col-12">
      <card title="Withdraw">
        <div class="col-md-4 px-md-1">
          <base-select :options="optionsArea" label="Choose Area" v-model="dataInput.areaName" name="item-areaName">
          </base-select>
          <base-input label="Nickname" v-model="dataInput.nickName" name="item-nickName">
          </base-input>
          <base-select :options="optionsType" label="Loại giao dịch" v-model="dataInput.selectedType" name="item-type" >
          </base-select>
        </div>

        <base-button @click=getWithdraw()>Toàn bộ</base-button>
        <base-button @click=getWithdrawNick()>Search</base-button>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Areaname</th>
                <th>Nickname</th>
                <th>To</th>
                <th>Memo</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Time</th>
                <th>Status</th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in paginatedData" :key="index" >
                <td>{{ data.id }}</td>
                <td>{{ data.areaName }}</td>
                <td>{{ data.nickName }}</td>
                <td>{{ data.to }}</td>
                <td>{{ data.note }}</td>
                <td>{{ data.type === 'TI' ? 'Nội Bộ' : data.type === 'WE' ? 'BEP20' : data.type }}</td>
                <td class="green">${{ data.amount }}</td>
                <td>{{ new Date(data.createdAt).toLocaleString() }}</td>

                <td v-if="data.status == '1'" class="green">Done</td>
                <td v-if="data.status == '0'" class="red">Pending</td>
                <vs-button v-if="data.status == 0" @click=acceptWithdraw(data)>Duyệt</vs-button>
              </tr>
            </tbody>
          </table>
        </div>
        <vs-pagination v-model="page" :length="pageCount" @input="updateData"></vs-pagination>
      </card>
    </div>



  </div>
</template>
<script>
import { BaseTable } from "@/components";
import AuthenticationService from './services/AuthenticationService';
const tableColumns = ["AreaName", "NickName", "From", "Amount", "Status"];
import BaseSelect from '../components/BaseSelect.vue';

export default {
  components: {
    BaseTable,
    'base-select': BaseSelect,
  },
  props: {

  },
  data() {
    return {
      datawithdraw: [],
      itemsPerPage: 10,
      page: 1,
      dataInput: {
        areaName: "",
        nickName: "",
      },
      optionsArea: [
        { value: "BS", label: "BIGSTAR" },
      ],
      optionsType: [
        { value: "", label: "All" },
        { value: "WE", label: "BEP20" },
        { value: "TI", label: "Nội bộ"}
      ]
    };
  },
  computed: {
    pageCount() {

      return Math.ceil(this.datawithdraw.length / this.itemsPerPage);

    },
    paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.datawithdraw.slice(start, end);
    },
  },
  methods: {
    getWithdraw() {
      let obj = {
        areaName: this.dataInput.areaName,
        type: this.dataInput.selectedType,
      }
      AuthenticationService.getListWithdraw(obj)
        .then((resp) => {
          if (resp.data.success) {
            this.datawithdraw = resp.data.data;
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        })
    },
    getWithdrawNick() {
      let obj = {
        areaName: this.dataInput.areaName,
        nickName: this.dataInput.nickName,
        type: this.dataInput.selectedType,
      }
      AuthenticationService.getListWithdrawNick(obj)
        .then((resp) => {
          if (resp.data.success) {
            this.datawithdraw = resp.data.data;
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        })
    },
    acceptWithdraw(data) {
      let obj = {
        id: data.id,
        type: "accept"
      }
      AuthenticationService.confirmWithdraw(obj)
        .then((resp) => {
          if (resp.data.success) {
            return this.$vs.notification({
              text: 'Đã Duyệt thành công ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        })
      this.getWithdraw()
    },
    updateData(page) {
      this.page = page;
    }
  }
};
</script>
<style>
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table th {
  font-weight: bold;
  background-color: #00172e;
  border-bottom: 2px solid #dee2e6;
}

.table tbody tr:nth-child(even) {
  background-color: #070025;
}
</style>
